<template>
    <div>
        <v-container>
          <v-card style="background-color: #f5f5f5" elevation="3">
            <div class="mt-2 mb-5 text-h5 text-sm-h5 text-md-h4 text-lg-h4 ma-3 text-xl-h4 text-center">
              <span v-if="isNew">Nueva</span>
              <span v-if="!isNew">Editar</span>
              máquina
            </div>

            <v-form
              ref="form"
              v-model="isValid"
              autocomplete="off"
            >
            <v-tabs v-model="tab" background-color="transparent" show-arrows>
              <v-tabs-slider color="blue"></v-tabs-slider>

              <v-tab href="#tab-maq">Máquina</v-tab>
              <v-tab href="#tab-emp">Empresas</v-tab>
            </v-tabs>

            <v-tabs-items v-model="tab" touchless>
              <v-tab-item :value="'tab-maq'">
                <v-card style="background-color: #F5F5F5" flat>
                  <!-- FORMULARIO MAQUINA -->
                  <v-row>
                    <v-col class="px-7 mt-4" cols="12" sm="9" md="6" lg="6" xl="5">
                      <v-text-field
                          class="mb-n7"
                          outlined
                          dense
                          text
                          v-model="maq.MaqNom"
                          required
                          :rules="[v => !!v || 'El nombre es obligatorio']"
                          label="Nombre *"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="px-7 mt-2 mb-2" cols="12" sm="6" md="4" lg="4" xl="4">
                      <v-checkbox
                          class="mt-n2"
                          hide-details
                          v-model="maq.MaqCtrlTtra"
                          label="Control de temperatura"
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                  <v-expand-transition>
                  <div v-show="maq.MaqCtrlTtra">
                    <v-row>
                        <v-col class="px-7 mt-2" cols="8" sm="5" md="4" lg="3" xl="3">
                            <v-text-field
                                hide-details outlined dense
                                type="number"
                                v-model="maq.MaqTtraMin"
                                label="Temperatura mínima"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="px-7 mt-2" cols="8" sm="5" md="4" lg="3" xl="3">
                            <v-text-field
                                hide-details outlined dense
                                type="number"
                                v-model="maq.MaqTtraMax"
                                label="Temperatura máxima"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="px-7 mt-2" cols="12" sm="9" md="6" lg="6" xl="5">
                            <v-text-field
                                hide-details outlined dense
                                v-model="maq.MaqTtraRangoTxt"
                                label="Rango de temperatura"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                  </div>
                  </v-expand-transition>
                </v-card>
              </v-tab-item>
              <v-tab-item :value="'tab-emp'" eager>
                <v-card style="background-color: #F5F5F5" flat>
                  <v-row class="mt-0">
                    <!-- EMPRESAS -->
                    <v-col class="px-7" cols="12" sm="9" md="8" lg="7" xl="6">
                      <EmpresasRelacionadas
                        ref="relatedEmps"
                        :empList="empDataList" 
                        :empTotal="empDataTotal" 
                        :loading="loading" 
                        @update-data="updateEmpData"
                      ></EmpresasRelacionadas>
                    </v-col>
                  </v-row>
                </v-card>
              </v-tab-item>
            </v-tabs-items>

            <!-- ACEPTAR / CANCELAR / BORRAR -->
            <v-row class="mt-2" justify="center">
                <v-btn
                    :loading="loading"
                    :disabled="!isValid"
                    @click="onBtnSave"
                    color="primary"
                    class="mt-2 mb-4 mr-2"
                    style="width:100px"
                >
                  Aceptar
                </v-btn>
                
                <v-btn
                    to="/maquinas"
                    class="mt-2 mb-4 mr-2"
                    style="width:100px"
                >
                  Cancelar
                </v-btn>

                <v-btn v-if="!isNew"
                color="error"
                class="mt-2 mb-4"
                style="width:100px"
                @click="onBtnShowDeleteModal()">
                  Borrar
                </v-btn>
            </v-row>
            </v-form>
          </v-card>
        </v-container>

        <!-- Modales de borrado -->
        <!-- Borrar Máquina -->
        <v-dialog
          v-model="showDeleteDialog"
          transition="dialog-bottom-transition"
          persistent
          width="500"
        >
          <v-card>
            <v-card-title class="text-h5 grey lighten-2">
              BORRAR MÁQUINA
            </v-card-title>

            <v-card-text class="mt-2">
              ¿Desea borrar la máquina? Esta acción no se podrá deshacer.
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                :loading="loading"
                color="primary"
                @click="onBtnDelete"
              >
                Aceptar
              </v-btn>
              <v-btn
                @click="onBtnCancelDelete"
              >
                Cancelar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
    </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import EmpresasRelacionadas from "../components/EmpresasRelacionadas.vue";
export default ({
    components: {
        EmpresasRelacionadas
    },
    computed: {
        ...mapState(['empId', 'perId', 'urlRaiz'])
    },
    data () {
        return {
            tab: null,
            loading: false,
            isNew: true,
            isValid: false,
            showDeleteDialog: false,
            maq: {
                MaqId: 0,
                MaqNom: '',
                MaqCtrlTtra: false,
                MaqTtraMin: null,
                MaqTtraMax: null,
                MaqTtraRangoTxt: ''
            },
            // Datos tabla empresas
            empDataTotal: 0,
            empDataList: []
        }
    },
    // watch: {
    //   maq: {
    //     handler () {
    //         this.validate();
    //     },
    //     deep: true,
    //   }
    // },
    mounted() {
      this.isNew = this.$route.meta.isNew;
      
      if(!this.isNew) {
        this.maq.MaqId = this.$route.params.id;
        this.loadData();
      } else {
        // Usar eager en la v-tab-item para poder tener la ref cargada
        this.$refs.relatedEmps.loadDefaultEmps();
      }
    },
    methods: {
      goToList() {
        this.$router.push('/maquinas');
      },

      //#region Llamadas a la API
      getDataFromApi() {
        this.loading = true;
        return new Promise((resolve) => {
          const controllerParameters = {
            Action: 'GET_MAQ',
            MaqId: this.maq.MaqId,
            EmpId: this.empId
          };       
          const AuthToken = localStorage.getItem('token');
          axios({ method: "POST", "url": this.urlRaiz + "/api/maq", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
          .then(result => {
            const item = result.data.Maq;

            setTimeout(() => {
                this.loading = false;
                resolve({
                item
              })
            }, 1000)
          })
          .catch(error => {
            if(error != null) {
                this.loading = false;
                alert("Error al cargar la máquina");
                this.goToList();
            }
          });
        })
      },
      postDataToApi() {
        this.loading = true;

        // Comprobamos si se tiene que controlar la temperatura
        if(!this.maq.MaqCtrlTtra) {
          this.maq.MaqTtraMin = null;
          this.maq.MaqTtraMax = null;
          this.maq.MaqTtraRangoTxt = '';
        }

        // Asignamos los IDs de los objetos hijos
        const empIds = this.empDataList.map(m => m.EmpId);

        return new Promise((resolve) => {
          const controllerParameters = {
            Action: 'SAVE_ENTITY',
            Maq: this.maq,
            EmpIds: empIds
          };
          const AuthToken = localStorage.getItem('token');
          axios({ method: "POST", "url": this.urlRaiz + "/api/maq", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
          .then(result => {
            const item = result.data;

            setTimeout(() => {
                this.loading = false;
                resolve({
                item
              })
            }, 1000)
          })
          .catch(error => {
            if(error != null) {
                this.loading = false;
                alert("Error al guardar la máquina");
            }
          });
        })
      },
      deleteDataFromApi(){
        this.loading = true;
        return new Promise((resolve) => {
          const controllerParameters = {
            Action: 'DEL_ENTITY',
            MaqId: this.maq.MaqId
          };       
          const AuthToken = localStorage.getItem('token');
          axios({ method: "POST", "url": this.urlRaiz + "/api/maq", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
          .then(result => {
            const item = result.data;

            setTimeout(() => {
                this.loading = false;
                resolve({
                item
              })
            }, 1000)
          })
          .catch(error => {
            if(error != null) {
                this.loading = false;
                alert("Error al borrar la máquina");
            }
          });
        })
      },
      //#endregion

      //#region Carga de datos
      loadData() {
        this.getDataFromApi()
          .then(data => {
            if(data.item == null) {
              alert("Máquina no encontrada");
              this.goToList();
              return;  
            }

            this.maq = data.item;
            
            this.empDataTotal = data.item.MaqEmp.length;
            this.empDataList = [];
            data.item.MaqEmp.forEach(i => {
              this.empDataList.push({
                EmpId: i.Emp.EmpId,
                EmpNom: i.Emp.EmpNom
              });
            });
          });
      },
      //#endregion

      //#region Click guardado
      onBtnSave() {
          this.validate();
          if(!this.isValid) { return; }

          this.postDataToApi().then(data => {
            if(data.item.MaqId != null) {
                this.goToList();
            }
            else {
              alert("Error al guardar la máquina.");
            }
          });
      },
      //#endregion

      //#region Click borrar
      onBtnDelete(){
        this.deleteDataFromApi().then(data => {
          if (data.item.Success) {
            this.showDeleteDialog = false;
            this.goToList();
          }
          else {
            alert(data.item.Message);
          }
        });
      },
      //#endregion

      //#region Gestión de modales
      onBtnShowDeleteModal(){
        this.showDeleteDialog = true;
      },
      onBtnCancelDelete() {
        this.showDeleteDialog = false;
      },
      //#endregion

      //#region Evento actualización empresas
      updateEmpData(data) {
        this.empDataList = data.list;
        this.empDataTotal = data.total;
      },
      //#endregion

      //#region Validar formulario
      validate() {
          this.$refs.form.validate();
      }
      //#endregion
    } 
})
</script>
